body{
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #fcfaf9;
}
html, body {
  height: 100%;
}
input, select, textarea, .datePicker{
  background-color: #fcfaf9 !important;
  border-radius: 40px !important;
  padding-left: 20px !important;
  box-sizing: border-box;
}
textarea{
  padding-top: 20px !important;
}
.container{
  max-width: 500px !important;
}
.react-date-picker__wrapper{
  border-style: none !important;
}
.nav-link{
  color: #000 !important;
  background-color: #fcfaf9 !important;
  border: none !important;
}
.loader{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fcfaf97b;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader img{
  width: 100px;
  height: 100px;
  object-fit: contain;
  animation: breathe 2s infinite;

}
.quotation-info{
  color: rgb(99, 121, 132);
}
@keyframes breathe {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
  
.login-container{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.login-card{
  width: 400px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 0 5px 0 rgba(124, 124, 124, 0.2);
  padding: 50px;
  box-sizing: border-box;
}
.form-check-input{
  background-color: #212529 !important;
  border-style: none !important;
}
.btn-primary{
  background-color: #212529 !important;
  border-style: none !important;
  border-radius: 150px !important;
}
.btn-secondary{
  background-color: #c0d2f6 !important;
  border-style: none !important;
  border-radius: 150px !important;
  color: #212529 !important;
  height: 40px !important;
  font-weight: 600 !important;
}
.card{
  border-radius: 20px !important;
  border-style: none !important;
  box-shadow: 0 0 2px 0 rgba(0,0,0,0.2);
}
.title{
  font-size: 30px;
  font-weight: bold;
}


.sub-title{
  font-size: 20px;
  font-weight: bold;
  text-align: left;
}

.search-bar {
  height: 0px;
  background-color: #212529;
  float: left;
  position: fixed;
}
.bg-hr{
  background-color: #fcfaf9;
}

.enquiry-card{
  background-color: #fcfaf9;
  color: #212529;
  border-style: none;
  border-radius: 25px;
  min-width: 300px;
  white-space: break-spaces;
}

.w-300{
  width:300px;
}

.profile-name{
  height: 10px;
}

.service{
  background-color: #fff;
  color: #212529;
  border-style: none;
  border-radius: 15px;
}

.loader{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fcfaf9bd;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.back-button{
 
  top: 20px;
  left: 20px;
  background-color: #c0d2f6;
  color: #000;
  border-radius: 50%;
  padding: 5px 10px 5px 10px;
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}


.fab{
  position:fixed;
  bottom: 20px;
  right: 20px;
  background-color: #212529;
  color: #fff;
  border-radius: 30px;
  padding: 5px 10px 5px 10px;
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
  height: 50px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-backdrop{
  background-color: #2f2f2fbd !important;
  position: absolute;
}

.modal-container{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: fit-content;
  background-color: #fff;
  border-radius: 20px 20px 0 0;
  padding: 50px 20px 50px 20px; 
}

.react-calendar { 
  max-width: 100%;
  color: #222;
  border-style: none;
  border-radius: 8px;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
 }
 .react-calendar__navigation button {
  color: #212529;
  min-width: 44px;
  background: none;
  font-size: 16px;
  margin-top: 8px;
 }
 .react-calendar__navigation button:enabled:hover,
 .react-calendar__navigation button:enabled:focus {
  background-color: #f8f8fa;
 }
 .react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
 }
 abbr[title] {
  text-decoration: none;
 }
 /* .react-calendar__month-view__days__day--weekend {
  color: #d10000;
 } */
 .react-calendar__tile:enabled:hover,
 .react-calendar__tile:enabled:focus {
  background: #f8f8fa;
  color: #212529;
  border-radius: 6px;
 }
 .react-calendar__tile--now {
  background: #212529;
  border-radius: 6px;
  font-weight: bold;
  color: #212529;
 }
 .react-calendar__tile--now:enabled:hover,
 .react-calendar__tile--now:enabled:focus {
  background: #212529;
  border-radius: 6px;
  font-weight: bold;
  color: #212529;
 }
 .react-calendar__tile--hasActive:enabled:hover,
 .react-calendar__tile--hasActive:enabled:focus {
  background: #f8f8fa;
 }
 .react-calendar__tile--active {
  background: #212529;
  border-radius: 6px;
  font-weight: bold;
  color: white;
 }
 .react-calendar__tile--active:enabled:hover,
 .react-calendar__tile--active:enabled:focus {
  background: #212529;
  color: white;
 }
 .react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #f8f8fa;
 }
 .react-calendar__tile--range {
  background: #f8f8fa;
  color: #212529;
  border-radius: 0;
 }
 .react-calendar__tile--rangeStart {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background: #212529;
  color: white;
 }
 .react-calendar__tile--rangeEnd {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background: #212529;
  color: white;
 }

 .enquiries-container-horizontal{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  scrollbar-width: none;
  height: 100%;
  width: 100%;
  padding: 0px 20px 0px 20px;
 }
 .enquiries-container-horizontal::-webkit-scrollbar {
  display: none;
}

.banner{
  height: 200px;
  width:100%;
}
.banner img{
  height: 100%;
  width:100%;
  object-fit: cover;
  border-radius: 35px;
}

.banner .content{
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: start;
  padding-left: 20px;
  padding-bottom: 20px;
  background-image: linear-gradient(to top, black,#00000004);
  z-index: 999;
  color: #f0f0f0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 35px;
}
.banner a{
  text-decoration: none;
  color: #c0d2f6;
}

.auth-cover{
  width: 100%;
  height: 140px;
  max-height: 140px;
  background-image: url('../assets/images/bg_grey.png');
  background-size:cover;
}

.auth-cover img{
  height: 60px;
  width: 60px;
  border-radius: 30px;
  background-color: #fcfaf9;
  position:absolute;
  top:100px;
  left: 20px;
  object-fit: contain;
}

.form-control .disabled{
  background-color: #8e8b89 !important;
}